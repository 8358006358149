import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  UntypedFormControl,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import {
  DriverApiService,
  JobApiService,
  OperatorApiService,
  OrganisationApiService,
  StripeApiService,
  TravellerApiService,
} from '@fleet/api';
import { fuseAnimations } from '@fleet/fuse';
import {
  ApiResponse,
  DriverModel,
  IssueModel,
  JobModel,
  OperatorModel,
  OrganisationGroupModel,
  OrganisationModel,
  PaymentMethodModel,
  PaymentType,
  TravellerModel,
  SettingDefinitionModel,
  SettingModel,
} from '@fleet/model';

import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  filter,
  map,
  takeUntil,
} from 'rxjs';
import {
  BraintreePaymentMethodComponent,
  BrainTreeState,
} from '../braintree-payment-method/braintree-payment-method.component';
import { BraintreeHostedFieldsComponent } from '../braintree-hosted-fields/braintree-hosted-fields.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StripePaymentComponent } from '../stripe-payment/stripe-payment.component';
import { PaymentMethod } from '@stripe/stripe-js';
import { ProductConfigurationService } from '@fleet/product-configuration';
import { NotificationService } from '@fleet/notification';
import { OnscreenNotificationService } from '@fleet/ui';
import { successNotification } from '@fleet/utilities';
import { SettingService } from '@fleet/setting';

interface PaymentMethodCreateEditState {
  paymentMethod: PaymentMethodModel;
  loading: boolean;
  issues: IssueModel[];
  buttonLabel: string;
  title: string;
}

const initialState: PaymentMethodCreateEditState = {
  paymentMethod: null,
  buttonLabel: 'Create',
  issues: [],
  loading: false,
  title: 'New Payment Method',
};

@Component({
  selector: 'fleet-payment-method-create-edit',
  templateUrl: './payment-method-create-edit.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
})
export class PaymentMethodCreateEditComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  settlementPaymentMethodControl = new FormControl(null, [Validators.required]);
  apiLoadError = false;
  showCardDetails = true;
  billingAddressControl: FormControl = new FormControl(null, [
    Validators.required,
  ]);

  _paymentMethodType: string;
  @Input() set paymentMethodType(value: string) {
    this._paymentMethodType = value;

    if (value) {
      this.paymentTypeControl.setValue(value);

      if (!this.paymentMethodForm && !this.payment) {
        this.buildForm(value);
      }
    }
    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
  }

  get paymentMethodType() {
    return this._paymentMethodType;
  }

  _mode: 'FORM_ONLY' | 'DIALOG' | 'SIDEBAR' = 'SIDEBAR';
  @Input() set mode(value: 'FORM_ONLY' | 'DIALOG' | 'SIDEBAR') {
    this._mode = value;
    if (value === 'FORM_ONLY') {
      setTimeout(() => {
        this.paymentTypeControl.setValue('CREDIT_CARD');
      }, 300);
    }
  }

  get mode() {
    return this._mode;
  }

  _organisation: OrganisationModel;
  @Input() set organisation(value: OrganisationModel) {
    this._organisation = value;
    if (value) {
      this.PAYMENT_TYPES = [
        { name: 'Credit Card', value: 'CREDIT_CARD' },
        { name: 'Organisation Account', value: 'ORGANISATION_ACCOUNT' },
      ];

      if (!this.paymentMethodForm) {
        this.buildForm('CREDIT_CARD');
      } else {
        setTimeout(() => {
          this.paymentTypeControl.setValue('CREDIT_CARD');
        }, 300);
      }

      this.paymentMethodForm.addControl(
        'displayName',
        new FormControl('', [
          Validators.required,
          Validators.pattern(`^[a-zA-Z ']+$`),
        ])
      );
      this.paymentMethodForm.updateValueAndValidity();
      this.changeDetectorRef.markForCheck();
    }
  }

  get organisation() {
    return this._organisation;
  }

  _driver: DriverModel;
  @Input() set driver(value: DriverModel) {
    this._driver = value;

    if (value) {
    }
  }

  get driver() {
    return this._driver;
  }

  _traveller: TravellerModel;
  @Input() set traveller(value: TravellerModel) {
    this._traveller = value;
    if (value) {
    }
  }

  get traveller() {
    return this._traveller;
  }

  @Input() organisationGroup: OrganisationGroupModel;

  @Input() job: JobModel;

  _operator: OperatorModel;
  @Input() set operator(value: OperatorModel) {
    this._operator = value;
    if (value) {
      this.PAYMENT_TYPES = [
        { name: 'Credit Card', value: 'CREDIT_CARD' },
        {
          name: 'Direct Debit Bank Account',
          value: 'DIRECT_DEBIT_BANK_ACCOUNT',
        },
      ];

      this.changeDetectorRef.markForCheck();
    }
  }

  get operator() {
    return this._operator;
  }

  @Output() onCreateSuccess = new EventEmitter();
  @Output() onEditSuccess = new EventEmitter();
  @Output() onCancel = new EventEmitter();

  brandConfig = {
    theme: {
      primary: '#E11D20',
      accent: '#000000',
      warn: '#dc2626',
      controls: '#000000',
    },
  } as any;

  paymentProvider: 'BRAINTREE' | 'STRIPE';

  paymentMethodCreateEditState: BehaviorSubject<PaymentMethodCreateEditState> =
    new BehaviorSubject(initialState);

  paymentTypeControl: UntypedFormControl = new UntypedFormControl(null);

  PAYMENT_TYPES = [
    { name: 'Credit Card', value: 'CREDIT_CARD' },
    { name: 'Direct Debit Bank Account', value: 'DIRECT_DEBIT_BANK_ACCOUNT' },
  ];

  braintreeStatus: any;
  stripeStatus: any;

  exteneralPaymentState: BehaviorSubject<{
    loading: boolean;
    buttonLabel: string;
  }> = new BehaviorSubject<{ loading: boolean; buttonLabel: string }>({
    loading: false,
    buttonLabel: 'Save',
  });

  unsubscribeAll: Subject<any> = new Subject();

  _braintreeHostedFields: BraintreeHostedFieldsComponent;
  @ViewChild(BraintreeHostedFieldsComponent, { static: false })
  set braintreeHostedFields(value: BraintreeHostedFieldsComponent) {
    this._braintreeHostedFields = value;
    if (value) {
      value.status$.pipe(takeUntil(this.unsubscribeAll)).subscribe({
        next: (hostedFieldsStatus: any) => {
          console.log('Braintree status update');
          this.braintreeStatus = hostedFieldsStatus;
          this.exteneralPaymentState.next({
            loading:
              (hostedFieldsStatus.loading ||
                !hostedFieldsStatus.isInitialised) &&
              !this.apiLoadError &&
              hostedFieldsStatus.issues.length === 0,
            buttonLabel: 'Save',
          });
          if (hostedFieldsStatus.issues.length > 0) {
            this.paymentMethodCreateEditState.next({
              ...this.paymentMethodCreateEditState.value,
              issues: hostedFieldsStatus.issues,
            });
          }

          this.changeDetectorRef.markForCheck();
          this.changeDetectorRef.detectChanges();
        },
      });
    }
  }
  get braintreeHostedFields() {
    return this._braintreeHostedFields;
  }

  _stripePayment: StripePaymentComponent;
  @ViewChild(StripePaymentComponent, { static: false })
  set stripePayment(value: StripePaymentComponent) {
    this._stripePayment = value;
    if (value) {
      value.status$.pipe(takeUntil(this.unsubscribeAll)).subscribe({
        next: (stripeStatus: any) => {
          console.log('Stripe status update');
          this.stripeStatus = stripeStatus;
          this.exteneralPaymentState.next({
            loading:
              (stripeStatus.loading || !stripeStatus.isInitialised) &&
              !this.apiLoadError &&
              stripeStatus.issues.length === 0,
            buttonLabel: 'Save',
          });
          if (stripeStatus.issues.length > 0) {
            this.paymentMethodCreateEditState.next({
              ...this.paymentMethodCreateEditState.value,
              issues: stripeStatus.issues,
            });
          }
          this.changeDetectorRef.markForCheck();
          this.changeDetectorRef.detectChanges();
        },
      });
    }
  }
  get stripePayment() {
    return this._stripePayment;
  }

  paymentMethodForm: FormGroup;

  _payment: any;
  @Input()
  set payment(value: any) {
    this._payment = value;
    if (value) {
      if (value.paymentType === 'CREDIT_CARD' && value.organisationId) {
        //org braintree
        this.paymentMethodType = 'CREDIT_CARD';
        this.buildForm('CREDIT_CARD');
        this.paymentMethodForm.addControl(
          'displayName',
          new FormControl('', [
            Validators.required,
            Validators.pattern(`^[a-zA-Z ']+$`),
          ])
        );
        this.paymentMethodForm.get('displayName').patchValue(value.displayName);
        this.paymentMethodForm.updateValueAndValidity();
      } else {
        this.paymentMethodType = value.paymentType;
        this.buildForm(value.paymentType);
        this.paymentMethodForm.updateValueAndValidity();

        setTimeout(() => {
          if (this.paymentMethodForm.get('account')) {
            this.paymentMethodForm.get('account').patchValue(value);
          }
        }, 10);
      }

      this.paymentMethodCreateEditState.next({
        ...this.paymentMethodCreateEditState.value,
        paymentMethod: value as any,
        title: 'Edit Payment Method',
        buttonLabel: 'Update',
      });

      if (value.paymentType === 'ORGANISATION_ACCOUNT') {
        this.paymentMethodForm.get('displayName').patchValue(value.displayName);
      }

      this.changeDetectorRef.markForCheck();
    }
  }
  get payment() {
    return this._payment;
  }

  get externalErrors(): Observable<any> {
    if (this.paymentProvider === 'BRAINTREE' && this.braintreeHostedFields) {
      return (
        this.braintreeHostedFields?.issues.pipe(
          map((issues) => {
            if (issues.length > 0) {
              this.apiLoadError = true;
            }
            this.changeDetectorRef.markForCheck();
            this.changeDetectorRef.detectChanges();
            return issues;
          })
        ) ?? new Observable((observer) => observer.next([]))
      );
    } else if (this.paymentProvider === 'STRIPE' && this.stripePayment) {
      return (
        this.stripePayment?.issues.pipe(
          map((issues) => {
            if (issues.some((issue) => issue.type === 'StripeLoadError')) {
              //SPECIFIC STRIPE LOAD ERROR - API KEY IS BAD. HIDE THE FORM SINCE NONE OF IT WORKS NOW.
              this.apiLoadError = true;

              this.changeDetectorRef.markForCheck();
              this.changeDetectorRef.detectChanges();
            }
            return issues;
          })
        ) ?? new Observable((observer) => observer.next([]))
      );
    } else {
      return new Observable((observer) => observer.next([]));
    }
  }

  constructor(
    private organisationApiService: OrganisationApiService,
    private operatorApiService: OperatorApiService,
    private driverApiService: DriverApiService,
    private changeDetectorRef: ChangeDetectorRef,
    private jobApiService: JobApiService,
    private travellerApiService: TravellerApiService,
    private fb: FormBuilder,
    private onScreenNotificaitonService: OnscreenNotificationService,
    private settingsService: SettingService
  ) {
    this.settingsService
      .getSettingByPath$('network.payment.storedcard')
      .pipe(
        takeUntilDestroyed(),
        filter((setting: SettingModel) => !!setting),
        map((setting: SettingModel) => {
          const paymentProvider = setting.data.defaultPaymentProvider;
          console.log('Mapped Payment Provider:', paymentProvider);
          return paymentProvider;
        })
      )
      .subscribe({
        next: (paymentProvider: any) => {
          this.paymentProvider = paymentProvider;
          this.changeDetectorRef.markForCheck();

          setTimeout(() => {
            this.externalErrors
              .pipe(takeUntil(this.unsubscribeAll))
              .subscribe((issues: IssueModel[]) => {
                if (issues.length > 0) {
                  this.apiLoadError = true;

                  //an issue has occurred on stripe or braintree - turn off all loading
                  this.paymentMethodCreateEditState.next({
                    ...this.paymentMethodCreateEditState.value,
                    issues: issues,
                    loading: false,
                  });
                  this.exteneralPaymentState.next({
                    ...this.exteneralPaymentState.value,
                    loading: false,
                  });

                  this.changeDetectorRef.markForCheck();
                }
              });
          }, 200);
        },
        error: (err: any) => {
          console.error('Error fetching payment provider:', err);

          this.paymentMethodCreateEditState.next({
            ...this.paymentMethodCreateEditState.value,
            loading: false,
            issues: [
              {
                message:
                  'An error has occurred. Please try again, or contact support@ingogo.mobi for further assistance.',
                type: 'StripeLoadError',
              } as any,
            ],
          });
          this.apiLoadError = true;
        },
      });
  }

  ngOnInit(): void {
    this.paymentTypeControl.valueChanges.subscribe((value) => {
      this.buildForm(value);
      if (this.organisation) {
        this.paymentMethodForm.addControl(
          'displayName',
          new FormControl('', [
            Validators.required,
            Validators.pattern(`^[a-zA-Z ']+$`),
          ])
        );
        this.paymentMethodForm.updateValueAndValidity();
      }

      this.changeDetectorRef.markForCheck();
    });
  }

  ngAfterViewInit(): void {}

  buildForm(paymentType: string) {
    switch (paymentType) {
      case 'CREDIT_CARD':
      case 'STRIPE':
      case 'BRAINTREE':
      case 'STORED_CARD':
        this.paymentMethodForm = this.fb.group({});

        break;

      case 'ORGANISATION_ACCOUNT':
        this.paymentMethodForm = this.fb.group({
          displayName: [
            null,
            [Validators.required, Validators.pattern(`^[a-zA-Z ']+$`)],
          ],
          contractId: [null, !this.payment ? [Validators.required] : null],
          type: [paymentType],
        });
        break;

      case 'DIRECT_DEBIT_BANK_ACCOUNT':
        this.paymentMethodForm = this.fb.group({
          type: [paymentType],
          account: [null, [Validators.required]],
        });
        break;

      case 'SETTLEMENT_BANK_ACCOUNT':
      case 'EVEREE':
        this.paymentMethodForm = this.fb.group({
          type: [paymentType],
          account: [null, [Validators.required]],
        });
        break;

      default:
        this.paymentMethodForm = this.fb.group({
          type: [null],
        });
        break;
    }
    this.paymentMethodForm.updateValueAndValidity();

    this.changeDetectorRef.markForCheck();
  }

  toTitleCase(str: string) {
    return str.replace(/\w\S*/g, function (txt: string) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  submitExternalPaymentMethod() {
    if (this.paymentProvider === 'BRAINTREE') {
      this.submitBraintreePaymentMethod();
    } else {
      this.submitStripePaymentMethod();
    }
  }

  submitBraintreePaymentMethod() {
    if (this.organisation && this.payment) {
      this.submit();
    } else {
      this.braintreeHostedFields.requestPayment(
        this.billingAddressControl.value
      );
    }
  }

  submitStripePaymentMethod() {
    this.paymentMethodCreateEditState.next({
      ...this.paymentMethodCreateEditState.value,
      loading: true,
      issues: [],
    });
    this.stripePayment.onSubmit();
  }

  cancel() {
    this.onCancel.emit();
  }

  save() {
    this.paymentMethodCreateEditState.next({
      ...this.paymentMethodCreateEditState.value,
      loading: true,
      issues: [],
    });

    let createCall: any;

    let payload = {} as any;

    let account;
    let type;

    switch (this.paymentTypeControl.value) {
      case 'DIRECT_DEBIT_BANK_ACCOUNT':
        payload = <PaymentMethodModel>{
          account: this.paymentMethodForm.get('account').value,
          displayName: this.paymentMethodForm.get('account').value.accountName,
          type: 'DIRECT_DEBIT_BANK_ACCOUNT',
        };
        break;
      case 'SETTLEMENT_BANK_ACCOUNT':
        account = this.paymentMethodForm.get('account').value;
        type = this.paymentMethodForm.get('account').value.paymentProvider;
        delete account['paymentProvider'];

        payload = <PaymentMethodModel>{
          account: account,
          displayName: this.paymentMethodForm.get('account').value.accountName,
          type: type,
        };
        break;

      case 'ORGANISATION_ACCOUNT':
        //if ORGANISATION_ACCOUNT we need to get the display name and make it unique rather than using account name
        payload = <PaymentMethodModel>{
          displayName: this.paymentMethodForm.get('displayName').value,
          type: 'ORGANISATION_ACCOUNT',
        };
        break;

      default:
        break;
    }

    if (this.operator) {
      createCall = this.operatorApiService.addPaymentMethod(
        payload,
        this.operator.operatorId
      );
    }

    if (this.organisation) {
      if (this.paymentTypeControl.value !== 'ORGANISATION_CARD') {
        payload = Object.assign({}, payload, {
          account: {
            contractId: this.paymentMethodForm.value.contractId,
          },
        });
      }

      createCall = this.organisationApiService.addPaymentMethod(
        payload,
        this.organisation.organisationId
      );
    }

    if (this.organisationGroup) {
      createCall =
        this.organisationApiService.createOrganisationGroupPaymentMethod(
          this.organisationGroup.organisationId,
          this.organisationGroup.organisationGroupId,
          payload
        );
    }

    if (this.driver) {
      createCall = this.driverApiService.createPaymentMethod(
        this.driver.driverId,
        payload
      );
    }

    if (this.job) {
      createCall = this.jobApiService.createPaymentMethod(
        this.job.jobId,
        payload
      );
    }
    console.log(
      'State loading:' + this.paymentMethodCreateEditState.value.loading
    );
    createCall.subscribe(
      (resp: ApiResponse<PaymentMethodModel> | any) => {
        this.paymentMethodCreateEditState.next({
          ...this.paymentMethodCreateEditState.value,
          paymentMethod: resp.data,
        });

        //if operator we are updating this as theri default billing
        if (this.operator) {
          const payload = {
            ...this.operator,
            billingPaymentMethod: {
              paymentMethodId: resp.data.paymentMethodId,
            },
          } as any;

          this.operatorApiService
            .updateOperator(payload, this.operator.operatorId)
            .subscribe({
              next: (operatorResp: ApiResponse<OperatorModel>) => {
                this.paymentMethodCreateEditState.next({
                  ...this.paymentMethodCreateEditState.value,
                  loading: false,
                });
                this.operator = operatorResp.data;

                this.onCreateSuccess.emit(resp.data);

                this.onScreenNotificaitonService.setNotification({
                  ...successNotification,
                  title: 'Successfully created payment method!',
                  subTitle:
                    'Payment method has been successfully created and is ready to use',
                });
              },
              error: (issues: IssueModel[]) => {
                this.paymentMethodCreateEditState.next({
                  ...this.paymentMethodCreateEditState.value,
                  loading: false,
                  issues: issues,
                });
              },
            });
        } else if (this.job) {
          this.onScreenNotificaitonService.setNotification({
            ...successNotification,
            title: 'Successfully created payment method!',
            subTitle:
              'Payment method has been successfully created and is ready to use',
          });
          //the create payment method call on a job returns a job
          //the job create that is using this as a dialog is expecting a job.
          this.onCreateSuccess.emit(resp.data);
        } else {
          this.onScreenNotificaitonService.setNotification({
            ...successNotification,
            title: 'Successfully created payment method!',
            subTitle:
              'Payment method has been successfully created and is ready to use',
          });
          this.onCreateSuccess.emit(
            this.paymentTypeControl.value === 'BANK_DEPOSIT'
              ? { type: 'BANK_DEPOSIT' }
              : resp.data
          );
        }
      },
      (error: IssueModel[]) => {
        this.paymentMethodCreateEditState.next({
          ...this.paymentMethodCreateEditState.value,
          loading: false,
          issues: error,
        });
      }
    );
  }

  edit() {
    this.paymentMethodCreateEditState.next({
      ...this.paymentMethodCreateEditState.value,
      loading: true,
      issues: [],
    });

    let update = {} as any;

    switch (this.paymentTypeControl.value) {
      case 'DIRECT_DEBIT_BANK_ACCOUNT':
        update = <PaymentMethodModel>{
          account: this.paymentMethodForm.get('account').value,
          displayName: this.paymentMethodForm
            .get('displayName')
            .value.toUpperCase(),
          type: 'DIRECT_DEBIT_BANK_ACCOUNT',
          paymentMethodId:
            this.paymentMethodCreateEditState.value.paymentMethod
              .paymentMethodId,
        };
        break;
      case 'SETTLEMENT_BANK_ACCOUNT':
        update = <PaymentMethodModel>{
          account: this.paymentMethodForm.get('account').value,
          displayName: this.driver
            ? this.paymentMethodForm
                .get('account')
                .value.accountName.toUpperCase()
            : this.paymentMethodForm.get('displayName').value.toUpperCase(),
          type: 'SETTLEMENT_BANK_ACCOUNT',
          paymentMethodId:
            this.paymentMethodCreateEditState.value.paymentMethod
              .paymentMethodId,
        };
        break;

      case 'ORGANISATION_ACCOUNT':
        //if ORGANISATION_ACCOUNT we need to get the display name and make it unique rather than using account name
        update = <PaymentMethodModel>{
          // account: this.paymentMethodForm.get('account').value,
          displayName: this.paymentMethodForm.get('displayName').value,
          type: 'ORGANISATION_ACCOUNT',
        };
        break;

      default:
        break;
    }

    let updateCall: any;

    if (this.operator) {
      updateCall = this.operatorApiService.updatePaymentMethod(
        update,
        this.operator.operatorId
      );
    } else if (this.organisation) {
      update = {
        paymentMethodId:
          this.paymentMethodCreateEditState.value.paymentMethod.paymentMethodId,
        displayName: this.paymentMethodForm.get('displayName').value,
      } as any;

      updateCall = this.organisationApiService.updatePaymentMethod(
        update,
        this.organisation.organisationId
      );
    } else if (this.organisationGroup) {
      update = {
        paymentMethodId:
          this.paymentMethodCreateEditState.value.paymentMethod.paymentMethodId,
        displayName: this.paymentMethodForm.get('displayName').value,
      } as any;

      updateCall =
        this.organisationApiService.updateOrganisationGroupPaymentMethod(
          this.organisationGroup.organisationId,
          this.organisationGroup.organisationGroupId,
          update
        );
    } else if (this.driver) {
      updateCall = this.driverApiService.updateSettlementAccount(
        this.driver.driverId,
        update.paymentMethodId,
        update
      );
    }

    updateCall.subscribe(
      (resp: ApiResponse<PaymentMethodModel> | any) => {
        this.paymentMethodCreateEditState.next({
          ...this.paymentMethodCreateEditState.value,
          loading: false,
          paymentMethod: resp.data,
        });

        //operator has this set.
        if (this.operator) {
          const payload = {
            ...this.operator,
            billingPaymentMethod: {
              paymentMethodId: resp.data.paymentMethodId,
            },
          } as any;

          this.operatorApiService
            .updateOperator(payload, this.operator.operatorId)
            .subscribe({
              next: (operatorResp: ApiResponse<OperatorModel>) => {
                this.paymentMethodCreateEditState.next({
                  ...this.paymentMethodCreateEditState.value,
                  loading: false,
                });

                this.operator = operatorResp.data;
                this.onEditSuccess.emit(resp.data);
              },
              error: (issues: IssueModel[]) => {
                this.paymentMethodCreateEditState.next({
                  ...this.paymentMethodCreateEditState.value,
                  loading: false,
                  issues: issues,
                });
              },
            });
        } else {
          this.onEditSuccess.emit(resp.data);
        }
      },
      (error: IssueModel[]) => {
        this.paymentMethodCreateEditState.next({
          ...this.paymentMethodCreateEditState.value,
          loading: false,
          issues: error,
        });
      }
    );
  }

  submit() {
    if (this.paymentMethodCreateEditState.value.paymentMethod) {
      this.edit();
    } else {
      this.save();
    }
  }

  createPaymentMethodFromStripe(token: string) {
    const payload = {
      token: token,
      type: 'STORED_CARD',
    } as any;

    //Do we need to apply display name?
    if (this.paymentMethodForm?.get('displayName')?.value) {
      payload['displayName'] = this.paymentMethodForm.get('displayName').value;
    }

    this.paymentMethodCreateEditState.next({
      ...this.paymentMethodCreateEditState.value,
      loading: true,
      issues: null,
    });

    let apiCall;

    if (this.driver) {
      apiCall = this.driverApiService.createPaymentMethod(
        this.driver.driverId,
        payload
      );
    } else if (this.traveller) {
      apiCall = this.travellerApiService.createPaymentMethod(
        this.traveller.travellerId,
        payload
      );
    } else if (this.organisation) {
      apiCall = this.organisationApiService.addPaymentMethod(
        payload,
        this.organisation.organisationId
      );
    } else if (this.operator) {
      apiCall = this.operatorApiService.addPaymentMethod(
        payload,
        this.operator.operatorId
      );
    }

    this.changeDetectorRef.markForCheck();

    apiCall.subscribe({
      next: (resp: ApiResponse<PaymentMethodModel> | any) => {
        const paymentMethod = resp.data;
        console.log('Setup intent completed successfully', paymentMethod);

        //We need to kick off the 3ds flow
        if (
          paymentMethod.status === 'PENDING_VERIFICATION' ||
          paymentMethod?.card?.verification?.verificationStatus ===
            'requires_action'
        ) {
          this.stripePayment.handleNextAction(paymentMethod);
        } else {
          this.stripePayment.loading.next(false);
          this.onScreenNotificaitonService.setNotification({
            ...successNotification,
            title: 'Successfully created payment method!',
            subTitle:
              'Payment method has been successfully created and is ready to use',
          });
          this.onCreateSuccess.emit(resp.data);
        }

        this.paymentMethodCreateEditState.next({
          ...this.paymentMethodCreateEditState.value,
          loading: false,
          paymentMethod: resp.data,
        });
        this.changeDetectorRef.markForCheck();
      },
      error: (issues: IssueModel[]) => {
        console.error('Error completing setup intent', issues);
        this.stripePayment.resetCardForm();
        this.stripePayment.loading.next(false);

        this.paymentMethodCreateEditState.next({
          ...this.paymentMethodCreateEditState.value,
          loading: false,
          issues: issues,
        });
        this.changeDetectorRef.markForCheck();
      },
    });
  }

  createPaymentMethodFromNonce(payload: {
    nonce: string;
    deviceData: string;
    type: string;
  }) {
    this.braintreeHostedFields.loading.next(true);
    this.braintreeHostedFields.loadingMessage = 'Saving card';
    let createCall;
    if (this.driver) {
      createCall = this.driverApiService.createNoncePaymentMethod(
        this.driver.driverId,
        payload
      );
    } else if (this.job) {
      createCall = this.jobApiService.createPaymentMethod(
        this.job.jobId,
        payload
      );
    } else if (this.operator) {
      createCall = this.operatorApiService.createNoncePaymentMethod(
        this.operator.operatorId,
        payload
      );
    } else if (this.organisation) {
      //Grab the display name

      payload = {
        ...payload,
        displayName: this.paymentMethodForm.value.displayName,
      } as any;

      createCall = this.organisationApiService.createNoncePaymentMethod(
        this.organisation.organisationId,
        payload
      );
    } else if (this.organisationGroup) {
      createCall =
        this.organisationApiService.createOrganisationGroupPaymentMethod(
          this.organisationGroup.organisationId,
          this.organisationGroup.organisationGroupId,
          payload as any
        );
    } else if (this.traveller) {
      createCall = this.travellerApiService.createPaymentMethod(
        this.traveller.travellerId,
        payload as any
      );
    }
    this.paymentMethodCreateEditState.next({
      ...this.paymentMethodCreateEditState.value,
      loading: true,
      issues: [],
    });
    createCall.subscribe(
      (resp: ApiResponse<PaymentMethodModel> | any) => {
        //if operator we are updating this as theri default billing

        this.paymentMethodCreateEditState.next({
          ...this.paymentMethodCreateEditState.value,
          paymentMethod: resp.data,
          loading: false,
        });
        // this.braintreeHostedFields.loading.next(false);

        this.onCreateSuccess.emit(
          this.paymentTypeControl.value === 'BANK_DEPOSIT'
            ? { type: 'BANK_DEPOSIT' }
            : resp.data
        );
      },
      (error: IssueModel[]) => {
        this.paymentMethodCreateEditState.next({
          ...this.paymentMethodCreateEditState.value,
          loading: false,
          issues: error,
        });
        this.braintreeHostedFields.loading.next(false);
      }
    );
  }

  brainTreeError(issues: IssueModel[]) {
    this.paymentMethodCreateEditState.next({
      ...this.paymentMethodCreateEditState.value,
      issues: issues,
      loading: false,
    });
    this.exteneralPaymentState.next({
      ...this.exteneralPaymentState.value,
      loading: false,
    });

    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next(null);
    this.unsubscribeAll.complete();
  }

  paymentMethod3dsCreated(paymentMethod: any) {
    this.onScreenNotificaitonService.setNotification({
      ...successNotification,
      title: 'Successfully created payment method!',
      subTitle:
        'Payment method has been successfully created and is ready to use',
    });
    this.onCreateSuccess.emit(paymentMethod);
  }
}
