import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  PaymentMethodModel,
  PaymentMethodSearchResultModel,
} from '@fleet/model';
import { evereeLogo } from '@fleet/shared';

@Component({
  selector: 'fleet-payment-method-icon',
  templateUrl: './payment-method-icon.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentMethodIconComponent implements OnInit {
  imgSrc: string;
  @Input() set cardType(value: string) {
    if (value === 'master-card') {
      value = 'mastercard';
    }
    this.iconSvg = 'creditcards:' + value;
    this.changeDetectorRef.markForCheck();
  }
  @Input() set paymentMethod(
    value: PaymentMethodSearchResultModel | PaymentMethodModel | any
  ) {
    if (value) {
      if (
        value.paymentType === 'DIRECT_DEBIT_BANK_ACCOUNT' ||
        value.type === 'DIRECT_DEBIT_BANK_ACCOUNT'
      ) {
        this.iconSvg = 'currency_exchange';
      } else if (
        value.paymentType === 'STORED_CARD' ||
        value.type === 'STORED_CARD'
      ) {
        const card = value.cardType
          ? value.cardType.toLowerCase()
          : value.card.cardType.toLowerCase();
        if (card === 'american express') {
          this.iconSvg = 'creditcards:amex';
        } else {
          this.iconSvg = 'creditcards:' + card;
        }
      } else if (value.paymentType === 'CASH' || value.type === 'CASH') {
        this.iconSvg = 'monetization_on';
      } else if (
        value.paymentType === 'BANK_DEPOSIT' ||
        value.type === 'BANK_DEPOSIT'
      ) {
        this.iconSvg = 'account_balance';
      } else if (
        value.paymentType === 'SETTLEMENT_BANK_ACCOUNT' ||
        value.type === 'SETTLEMENT_BANK_ACCOUNT'
      ) {
        this.iconSvg = 'account_balance';
      } else if (value.paymentType === 'EVEREE' || value.type === 'EVEREE') {
        this.imgSrc = evereeLogo;
      }
      this.changeDetectorRef.markForCheck();
    }
  }
  iconSvg: string;
  @Input() styleClass: string;
  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {}
}
