import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

const form = {
  fromDate: new UntypedFormControl(),
  toDate: new UntypedFormControl(),
  bankAccountNumber: new UntypedFormControl(),
  bsb: new UntypedFormControl(),
  sort: new UntypedFormControl(),
};

@Injectable({
  providedIn: 'root',
})
export class SettlementSearchForm extends UntypedFormGroup {
  constructor() {
    super(form);
  }
}
